<template>
  <k-field-group language-prefix="document.fields">
    <k-form-dialog
      v-bind="$attrs"
      v-on="$listeners"
    >
      <KTextField
        v-model="values.name"
        field="name"
        grid="col-sm-4 pr-2"
        required
      />
      <KAutocomplete
        v-model="selectedCategory"
        :disabled="isUpdateForm"
        :items="folders"
        required
        field="categoryId"
        item-text="name"
        return-object
      />
      <KAutocomplete
        v-if="selectedCategory"
        v-model="selectedType"
        :disabled="isUpdateForm"
        :items="allowedTypes"
        required
        field="typeId"
        item-text="name"
        return-object
        @change="setType"
      />
      <KDateField
        v-model="values.expirationDate"
        field="expirationDate"
        :min-date="minimalDate"
        :max-date="maxDate"
      />
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import KDateField from '@/components/crud/fields/KDateField.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'DocumentForm',
  components: {
    KAutocomplete,
    KDateField,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    folders: {
      type: Array,
    },
    isUpdateForm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedCategory: null,
    selectedType: null,
  }),
  computed: {
    allowedTypes() {
      return this.selectedCategory.documentTypes.filter(type => type.canCreate);
    },
    minimalDate() {
      return dayjs();
    },
    maxDate() {
      return dayjs().add(99, 'year');
    },
  },
  watch: {
    'values.documentCategory'() {
      this.setCategoryByValues();
    },
    'values.documentType'() {
      this.setTypeByValues();
      this.setCategoryByValues();
    },
  },
  methods: {
    setType(selected) {
      this.values.documentTypeId = selected.id;
    },
    setCategoryByValues() {
      this.selectedCategory = this.folders.find((folder) => folder.id === this.values.documentCategory.id);
      this.values.documentCategoryId = this.values.documentCategory.id;
    },
    setTypeByValues() {
      this.selectedType = this.values.documentType;
      this.values.documentTypeId = this.values.documentType.id;
    },
  },
};
</script>

<template>
  <v-row class="ma-0 align-stretch">
    <vue-resizable
      :active="['r']"
      :disable-attributes="['l', 't', 'h']"
      :max-width="500"
      :min-width="100"
      :width="300"
      class="folders-wrapper"
      fit-parent
    >
      <Folders
        :folder-items="folders"
        @documentDropped="onDocumentDropped"
        @folderChanged="fetchFiles"
      />
      <VDivider vertical />
    </vue-resizable>
    <div
      class="files-wrapper flex-grow-1 d-flex ml-6"
      @dragover.prevent="handleDrag"
      @drop.prevent="handleDrop"
    >
      <Files
        :files="files"
        :is-loading="isLoading"
        v-on="$listeners"
        @change="reload"
      />
    </div>
  </v-row>
</template>

<script>
import Files from '@/modules/document/components/fileManager/Files.vue';
import Folders from '@/modules/document/components/fileManager/Folders.vue';
import VueResizable from 'vue-resizable';

export default {
  name: 'KFileManager',
  components: {
    Files,
    Folders,
    VueResizable,
  },
  props: {
    headCategory: {
      type: String,
      default: 'App',
    },
    folders: {
      type: Array,
      required: true,
    },
    fileFetcher: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    files: [],
    currentFolder: 0,
    requestParameters: {
      page: 1,
      categoryId: null,
      typeId: null,
    },
    isLoading: true,
  }),
  created() {
    this.fetchRootfiles();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    reload() {
      this.fetchFiles(this.currentFolder);
    },

    /**
     * @param id {Array}
     */
    fetchFiles(id) {
      this.currentFolder = id;
      if (typeof id[0] === 'string') {
        const splittedIds = id[0].split('.');
        this.fetchFilesFromType(splittedIds[1]);
      } else {
        this.fetchFilesFromCategory(id[0]);
      }
    },
    onDocumentDropped(event) {
      let categoryId,
        typeId;
      if (typeof event.idString === 'string') {
        if (event.idString.includes('.')) {
          const split = event.idString.split('.');
          categoryId = split[0];
          typeId = split[1];
        } else {
          categoryId = event.idString;
        }
      }
      this.$emit('documentDropped', {
        files: event.files,
        categoryId: parseInt(categoryId),
        typeId: parseInt(typeId),
      });

    },
    handleScroll() {
      if (this.isLoading) {
        return;
      }
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 400) {
        this.fetchNextPage();
      }
    },
    fetchRootfiles() {
      this.requestParameters = {
        page: 1,
      };
      this.processFileRequest();
    },
    fetchFilesFromType(typeId) {
      this.requestParameters = {
        page: 1,
        typeId: parseInt(typeId),
      };
      this.processFileRequest();
    },
    fetchFilesFromCategory(categoryId) {
      this.requestParameters = {
        page: 1,
        categoryId: categoryId,
      };
      this.processFileRequest();
    },
    async processFileRequest() {
      this.isLoading = true;
      const response = await this.fileFetcher(this.requestParameters.page, 100, undefined, undefined, undefined, [this.requestParameters.categoryId], [this.requestParameters.typeId]);
      this.files = this.formatFiles(response.data.data);
      this.lastPage = response.data.meta.lastPage;
      this.isLoading = false;
    },
    async fetchNextPage() {
      if (this.lastPage === this.requestParameters.page) {
        return;
      }
      this.isLoading = true;
      this.requestParameters.page++;
      const response = await this.fileFetcher(this.requestParameters.page, 100, undefined, undefined, undefined, [this.requestParameters.categoryId], [this.requestParameters.typeId]);

      this.files = [...this.files, ...this.formatFiles(response.data.data)];
      this.isLoading = false;
    },
    formatFiles(files) {
      return files.map(file => {
        const type = this.getTypeInTree(file.documentTypeId);
        if (!type) {
          return file;
        }
        return {
          ...file,
          canCreate: type.canCreate,
          canRead: type.canRead,
          canDelete: type.canDelete,
          canUpdate: type.canUpdate,
        };
      });
    },
    getTypeInTree(typeId) {
      for (let i = 0; i < this.folders.length; i++) {
        const category = this.folders[i];
        const result = category.children.find(type => typeId === type.typeId);
        if (result) {
          return result;
        }
      }
    },
    handleDrag(e) {
      e.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(e) {
      this.onDocumentDropped({
        files: e.dataTransfer.files,
        idString: this.currentFolder[0],
      });
    },
  },
};
</script>

<style>
.folders-wrapper {
  height: 100% !important;
}

.files-wrapper {
  width: 100px;
}
</style>

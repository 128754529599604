import { post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (employerId, page, perPage, search, sortBy, descending, documentCategories = [], documentTypes = [], params) => {
  return getPaginated(`employer/${employerId}/document`, page, perPage, search, sortBy, descending,
    {
      ...params,
      documentCategories,
      documentTypes,
    });
};

export const create = (employerId, document) => {
  return post(`employer/${employerId}/document`, document);
};

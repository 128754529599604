import { destroy, get, post, put } from '@/application/api/implementations/app';

export const index = () => {
  return get('setting/employer/document-category');
};

export const tree = () => {
  return get('employer/document-category/tree');
};

/**
 *
 * @param documentCategory {DocumentCategory}
 */
export const create = (documentCategory) => {
  return post('setting/employer/document-category', documentCategory);
};

/**
 *
 * @param documentCategory {DocumentCategory}
 */
export const update = (documentCategory) => {
  return put(`setting/employer/document-category/${documentCategory.id}`, documentCategory);
};

export const show = (documentCategoryId) => {
  return get(`setting/employer/document-category/${documentCategoryId}`);
};

export const remove = (documentCategoryId) => {
  return destroy(`setting/employer/document-category/${documentCategoryId}`);
};

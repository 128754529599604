import { destroy, get, put } from '@/application/api/implementations/app';

function update(document) {
  return put(`document/${document.id}`, document);
}

function show(documentId) {
  return get(`document/${documentId}`);
}

function remove(documentId) {
  return destroy(`document/${documentId}`);
}

export {
  remove,
  update,
  show,
};

<template>
  <div
    :class="{ 'cursor--pointer': file.canRead }"
    class="file text-center justify-center align-center my-4"
    @click="openFile"
    @mouseleave="leaveFile"
    @mouseover="hover = true"
  >
    <v-icon
      v-if="icon"
      :size="100"
      class="file-icon ma-3 mb-1"
    >
      {{ icon }}
    </v-icon>
    <v-img
      v-else-if="file.file.thumbnailUrl"
      :src="file.file.thumbnailUrl"
      class="ma-3 mb-1"
      height="100px"
      width="100px"
    >
      <template #placeholder>
        <VSkeletonLoader type="image" />
      </template>
    </v-img>
    <div class="caption text-truncate mx-3">
      {{ file.name }}
    </div>
    <v-scale-transition>
      <v-speed-dial
        v-if="hover && canAnything"
        v-model="fab"
        absolute
        direction="bottom"
        open-on-hover
        right
        top
      >
        <template #activator>
          <v-btn
            color="primary"
            dark
            fab
            small
          >
            <v-icon v-if="fab">
              $clear
            </v-icon>
            <v-icon v-else>
              $fabDots
            </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-if="file.canRead"
          color="primary"
          dark
          fab
          small
          @click.stop="openFile"
        >
          <v-icon>$visible</v-icon>
        </v-btn>
        <v-btn
          v-if="file.canUpdate"
          color="primary"
          dark
          fab
          small
          @click.stop="$emit('click:edit', file.id)"
        >
          <v-icon>$edit</v-icon>
        </v-btn>
        <v-btn
          v-if="file.canDelete"
          color="primary"
          dark
          fab
          small
          @click.stop="deleteFile"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-scale-transition>
    <FileDialog
      v-model="dialog"
      :file="file.file"
      :is-image="!icon"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import { remove } from '@/modules/document/api/document.js';
import FileDialog from '@/modules/document/components/fileManager/FileDialog.vue';

export default {
  name: 'File',
  components: { FileDialog },
  props: {
    file: {
      type: Object,
    },
  },
  data: () => ({
    hover: false,
    fab: false,
    dialog: false,
  }),
  computed: {
    canAnything() {
      return this.file.canUpdate || this.file.canRead || this.file.canDelete;
    },
    icon() {
      const type = this.file.file.type;
      if (!type) {
        return '$fileUnknown';
      }
      if (type.match('image.*')) {
        return ''; // preview will be shown
      }
      if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(type)) {
        return '$fileWord';
      }
      if (['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(type)) {
        return '$fileExcel';
      }
      if (type.match('video.*')) {
        return '$fileVideo';
      }
      if (['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'].includes(type)) {
        return '$filePowerpoint';
      }
      if (type === 'application/pdf') {
        return '$filePdf';
      }
      return '$fileUnknown';
    },
  },
  methods: {
    leaveFile() {
      this.hover = false;
      this.fab = false;
    },
    openFile() {
      if (!this.file.canRead) {
        return;
      }
      this.dialog = true;
    },
    deleteFile() {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('actions.deleteConfirmation.body', { resource: this.$tc('document.title', 1) }),
        confirmCallback: async () => {
          await remove(this.file.id);
          this.$emit('change');
        },
      });
    },
  },
};
</script>

<style lang="scss">

.file {
  height:   130px;
  position: relative;
  width:    130px;

  .v-speed-dial {
    right: -15px;
    top:   -15px;

    .v-speed-dial__list {
      padding: 2px;
    }
  }

  .file-icon {
    height: 100px;
    width:  100px;
  }
}
</style>

import Model from '@/application/models/model.js';

class Document extends Model {
  id = 0;
  documentTypeId = 0;
  documentType = null;
  documentCategory = null;
  name = '';
  file = null;
  expirationDate = '';

  mapForRequest() {
    return {
      name: this.name,
      documentTypeId: this.documentTypeId,
      file: this.file,
      expirationDate: this.expirationDate,
    };
  }
}

export default Document;

<template>
  <v-dialog
    v-model="dialog"
  >
    <v-row
      justify="center"
      align="center"
      class="ma-0 black"
    >
      <iframe
        v-if="!isImage"
        :src="file.url"
        height="800px"
        width="100%"
      />
      <v-img
        v-else-if="file.thumbnailUrl"
        :src="file.url"
        :lazy-src="file.thumbnailUrl"
        height="800px"
        width="800px"
        contain
      >
        <template #placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: 'FileDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isImage: {
      type: Boolean,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    value() {
      this.dialog = this.value;
    },
    dialog() {
      this.$emit('input', this.dialog);
    },
  },
  created() {
    this.dialog = this.value;
  },
};
</script>

<template>
  <div
    @dragover.prevent="handleDrag"
    @drop.prevent="handleDrop"
  >
    <v-treeview
      :active.sync="active"
      :items="allowedFolders"
      :open.sync="open"
      activatable
      dense
      class="treeview-table"
    >
      <template
        slot="prepend"
        slot-scope="{ item, open: isOpen, active }"
      >
        <v-icon
          :data-category-id="item.id"
          class="cursor--pointer"
          @dragenter="open.push(item.id)"
        >
          {{ isOpen || active ? '$folderOpen' : '$folderClosed' }}
        </v-icon>
      </template>
      <template
        slot="label"
        slot-scope="{ item }"
      >
        <div
          class="cursor--pointer"
          :data-category-id="item.id"
          @dragenter="open.push(item.id)"
        >
          {{ item.name }}
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import '@/scss/treeviewTable.scss';

export default {
  name: 'Folders',
  props: {
    folderItems: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    active: [],
    open: [0],
  }),
  computed: {
    allowedFolders() {
      return this.folderItems.map(category => {
        return {
          ...category,
          children: category.children.filter(type => type.canRead),
        };
      });
    },
  },
  watch: {
    active() {
      this.$emit('folderChanged', this.active);
    },
  },
  methods: {
    handleDrag(e) {
      e.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(e) {
      let idString;
      if (e.target.hasAttribute('data-category-id')) {
        idString = e.target.getAttribute('data-category-id');
      }

      this.$emit('documentDropped', {
        files: e.dataTransfer.files,
        idString: idString,
      });
    },
  },
};
</script>

<style scoped>
.v-treeview {
  width: 100%;
}
</style>

<template>
  <v-container
    v-if="isLoading"
    style="width: 100%;"
    class="ma-0 pa-0"
  >
    <v-row class="mx-5 mt-4 mb-n1">
      <VProgressLinear indeterminate />
    </v-row>
    <v-row class="ma-2">
      <div
        v-for="i in 4"
        :key="i"
        style="width: 100px"
        class="ma-3"
      >
        <VSkeletonLoader
          max-height="100"
          max-width="100"
          type="image"
          class="mb-3"
        />
        <VSkeletonLoader
          max-width="100"
          type="text"
        />
      </div>
    </v-row>
  </v-container>
  <v-row
    v-else-if="hasFiles"
    class="ma-2 align-content-start"
  >
    <File
      v-for="file in files"
      :key="file.id"
      :file="file"
      v-on="$listeners"
    />
  </v-row>
  <v-row
    v-else
    align="start"
    justify="center"
  >
    <p class="ma-3">
      {{ $tc('document.empty') }}
    </p>
  </v-row>
</template>

<script>
import File from '@/modules/document/components/fileManager/File.vue';

export default {
  name: 'Files',
  components: { File },
  props: {
    files: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    fab: false,
  }),
  computed: {
    hasFiles() {
      return this.files && this.files.length > 0;
    },
  },
};
</script>
